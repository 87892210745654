<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Inventārs
      </h3>

    </template>

    <template v-slot:buttons>
      <div class="flex gap-3">
        <template v-if="items.length > 1">
          <Input name="search" placeholder="Meklēt" v-model="localSearch" :showPlaceholder="false" />

          <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" @click="showFullList = !showFullList" />
        </template>
      </div>
    </template>
    <template v-slot:content>

    <div v-if="items.length > 0" class="flex flex-col w-full">
        <SingleBranchInventoryItem v-for="(item, index) in allItems" :key="index" :item="item" />
    </div>

    </template>
  </item-card>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import Input from "@/components/Components/Input"
import SingleBranchInventoryItem from "@/components/Customer/Branches/Branch/SingleBranchInventoryItem"

export default {
  name: "BranchInventoryItems",
  components: {
    ItemCard,
    Input,
    SingleBranchInventoryItem,
  },
  props: ['items'],
  mounted() {
    this.showFullList ? this.allItems = [...this.items] : this.allItems = [...this.items].slice(0, 1)
  },
  data() {
    return {
      allItems: [],
      localSearch: "",
      showFullList: false,
    }
  },
  computed: {
      ...mapGetters({
          customer: 'customer',
          formsForDisplay: 'formsForDisplay',
      }),
  },
  watch: {
    items() {
      this.showFullList ? this.allItems = [...this.items] : this.allItems = [...this.items].slice(0, 1)
    },
    showFullList() {
      this.showFullList ? this.allItems = [...this.items] : this.allItems = [...this.items].slice(0, 1)
    },
    localSearch() {
      this.showFullList = true;
      this.allItems = this.items.filter(item => {
        if (item.name.toLowerCase().includes(this.localSearch.toLowerCase())
        || item.uuid.toLowerCase().includes(this.localSearch.toLowerCase())) {
          return item;
        }
      })
    },
  },
}
</script>

<style>

</style>